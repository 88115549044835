import { useEffect, useRef, useState } from "react";
import useGetSQLTables from "./Hooks/useGetSQLTables"
import { FaCircleCheck } from "react-icons/fa6";
import { CSVLink } from "react-csv";
import './sqltable.scss'

const SQLTables = ({ exid ,data}) => {



    const { headers, rows, options, setOptions, selectedOptions, setSelectedOptions,  loading } = useGetSQLTables(exid,data)

  const [isActive1, setIsActive1] = useState(false);

  let menuRef1 = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef1.current && !menuRef1.current.contains(event.target)) {
        setIsActive1(false);
        // setIsDateActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef1]);
  const getHeaders = ()=>{
    return headers.map(item=>{
      console.log(item)
      item =Object.keys(item)[0]
      return {label:item,key:item}
    })
  }

  function setDropDown() {
    return (
      <>
        <div ref={menuRef1} className="pyui_category">
          <div
            className={`pyui_category-inner ${isActive1 ? "expanded" : ""}`}
            onClick={(e) => setIsActive1(!isActive1)}
          >
            <span>{selectedOptions!==''?selectedOptions:options[0]}</span>
          </div>
          {isActive1 && (
            <ul className="pyui_category-list">
              {options?.map((option, i) => (
                <li key={option}
                  className={`pyui_category-list-item ${option === selectedOptions ? 'selected' : ''}`}
                  onClick={async (e) => {
                    setSelectedOptions(e.target.textContent);
                    setIsActive1(false)
                    // await getOptions()
                  }}>
                  <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {option}{option === selectedOptions && <FaCircleCheck color="green" />}
                  </span>
                  {/* {labels.label} */}
                </li>
              ))}
            </ul>
          )}
        </div>
      </>
    );
  }
  const headerKeys = headers?.map(header => Object.keys(header)[0]);
  return (
    <section className="sqltable-main">
      {loading ? (
        <section style={{ textAlign: 'center' }}>
          Loading...
        </section>
      ) : (
        <>
          <section className="sqltable-dropdown">
            <span> Select table :</span> {setDropDown()}
          <div>
            <CSVLink 
            data={rows} 
            headers={getHeaders()}
            filename={selectedOptions!==''?selectedOptions:options[0]}
            >
            <button className="link-button">

            Download CSV
            </button>
          </CSVLink>
            </div>
          </section>
          <section className="sqltable-table">
            <label>
            {selectedOptions!==''?selectedOptions:options[0]} table
            </label>
            <div style={{  
                          // marginTop: '.6rem',
                          height: '22rem',
                          overflowY: 'auto',}}>
            <table>
              <thead>
                <tr>
                  {headers?.map(header => (
                    <th key={Object.keys(header)[0]}>{Object.values(header)[0]}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows?.map((row, index) => (
                  <tr key={index}>
                    {headerKeys?.map(key => (
                      <td key={key}>{row[key]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
          </section>
        </>
      )}
    </section>
  )
}
export default SQLTables