import React from 'react'
import PulseLoader from 'react-spinners/PulseLoader';

export default function Loader({ isLoading, msg = 'Loading', size = 15, color = '#7b1fa2' }) {

  const override = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // marginTop: "10%",
    zIndex: '10'

  };
  return (
    <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'center' }}>
      {msg && <p>{msg}</p>}
      <PulseLoader
        color={color}
        loading={isLoading}
        cssOverride={override}
        size={size}
        className="pulse-loader"
        aria-label="Loading Spinner"
        data-testid="loader"
        speedMultiplier={0.5}

      />
    </div>
  )
}
