import PulseLoader from "react-spinners/PulseLoader";
import CommonEditiordebug from "./commonEditor";
import { BiArrowToRight, BiArrowToLeft } from "react-icons/bi";
import { ImZoomIn, ImZoomOut, ImCancelCircle } from "react-icons/im";
import { FaExpandArrowsAlt, FaCompressArrowsAlt } from "react-icons/fa";
import TextEditor from "./TextEditor";
import { useState } from "react";
import SQLTables from "./SQLTables";
import SQLPlayground from "./SQLPlayground";
import ImagePDFViewer from "./ImagPDFViewer";
import { langguagesHaveImage, languageHaveVideo } from "./Hooks/useQuesAnsAssertTest";
import StreamVideo from "./VideoStream/StreamVideo";

function LeftHandside({
  isModal,
  setIsMOdal,
  code,
  optionsD,
  submiited,
  submitting,
  override,
  coinMsg,
  isDebug,
  onChange,
  showImage,
  imageType,
  handleZoom,
  zoom,
  isSlide,
  setIsSlide,
  isFullScreen,
  setIsFullScreen,
  imageDivRef,
  imgRef,
  imgDimensions,
  showlefthandside,
  handleEditorDidMount,
  isNormal,
  title,
  isTextEditor, isEvaluation, step,
  setImageType
}) {
  console.log(
    "isNormal",
    isNormal,
    "showImage",
    showImage,
    "imagetype",
    imageType
  );
  const [mainTab, setMainTab] = useState("editor");
  const isShoWTables =(qlocation, language)=>{
    console.log('is sql',qlocation, language)
    if(language !== 'SQL'){
      return false
    }
    
    if(qlocation !== null && qlocation !== undefined){
  
      if(qlocation.includes('csv')){
        console.log('is sql inodes csv')
        return true
      }
      else if(qlocation.includes('txt') && qlocation.includes(',')){
        console.log('is sql inodes txt')

        return true
      }
      else if(qlocation && !qlocation.includes('txt') ){
        console.log('is sql inodes 2nd txt')

        return true
      }
      else{
        console.log('is sql inodes else')

        return false
      }
    }
    return false
   }

   const isShowPlayground = (qlocation, setp)=>{
    console.log('is sql',qlocation)
    if(qlocation == null ||qlocation ===undefined){
      return false
    }
    let language = step?.language
    let module = step?.module
    if(language === "SQL" && module === 'reader' ){
      if(!qlocation.includes('txt') && qlocation){
        return true
      }
      else if(qlocation.includes(',')){
        return true
      }
      // return true
    }
    return false
   }

   const isShowVideo = (step) =>{
    const videoExtentionArr = ['.mp4','.3gp', '.avi', '.mpg', '.mov', '.wmv']
    // if(step?.qlocation !== null && (!step?.qlocation.includes(',') && !step?.qlocation.includes('mp4'))){
    //   return false
    // }]
    if(step?.qlocation == null || step?.qlocation == undefined){
      return false
    }
    if(step?.qlocation.includes(',') && !step?.qlocation.includes('mp4')){

      let fileLocation = step?.qlocation?.split(',').filter(item => item.includes('.mp4'))[0] || ''
    console.log('filelocation',step, fileLocation !== undefined , fileLocation !== '' , languageHaveVideo.includes(step?.language))
      if(fileLocation !== undefined && fileLocation !== '' && languageHaveVideo.includes(step?.language) ){
        return true
      }
    }
    else if(step?.qlocation.includes('mp4')){
      return true
    }else if(step?.qlocation !== null && !step?.qlocation.includes(',')){
      return false
    }
      return false
   }


   const isShowImage = (step)=>{

    const fileExtenstions =['.png','.jepg','pdf']
    if(step?.qlocation == null || step?.qlocation == undefined){
      return false
    }
    if(langguagesHaveImage.includes(step.language))
      return false
    
    
    let fileLocation=''
    if(step?.qlocation.includes(',')){
      fileLocation = step.qlocation.split(',').filter(item=> {
        for (let i = 0; i < fileExtenstions.length; i++) {
          const element = fileExtenstions[i];
          if(item.includes(element)) return item
        }      
      })[0]
      console.log('image', fileLocation)
    }
    if(fileLocation !== undefined &&fileLocation !== ''){
      return true
    }
    return false
   }
  function Imgsidepanelbutton() {
    return (
      <>
        <div className="image-action-btn-div">
          {imageType !== "pdf" && (
            <>
              <button
                title="Zoom in"
                style={{
                  marginLeft: "1rem",
                  background: "transparent",
                  backgroundColor: "transparent",
                  border: "0px ",
                }}
                onClick={() => handleZoom(0.5)}
              >
                <ImZoomIn size={20} />
              </button>
              <button
                title="Zoom out"
                style={{
                  marginLeft: "1rem",
                  background: "transparent",
                  backgroundColor: "transparent",
                  border: "0px ",
                }}
                onClick={() => handleZoom(-0.5)}
                disabled={zoom <= 0.5}
              >
                <ImZoomOut size={20} />
              </button>
            </>
          )}
          <button
            title={isSlide ? "Expand" : "Collapse"}
            className="sliderBtn"
            style={{
              marginLeft: ".9rem",
              background: "transparent",
              backgroundColor: "transparent",
              border: "0px ",
            }}
            onClick={() => setIsSlide(!isSlide)}
          >
            {isSlide ? (
              <BiArrowToRight size={30} />
            ) : (
              <BiArrowToLeft size={30} />
            )}
          </button>
          <button
            className="sliderBtn"
            style={{
              marginLeft: "1rem",
              background: "transparent",
              backgroundColor: "transparent",
              border: "0px ",
            }}
            title={isFullScreen ? "Close" : "Full screen"}
            onClick={() => {
              (!isEvaluation && setIsSlide(isFullScreen ? true : false))
              setIsFullScreen(!isFullScreen);
            }}
          >
            {isFullScreen ? (
              <ImCancelCircle size={20} />
            ) : (
              <FaExpandArrowsAlt size={20} />
            )}
          </button>
        </div>
      </>
    );
  }
  function Pdfsidepanelbutton() {
    return (
      <>
        <div className="image-action-btn-div">
          <button
            title={isSlide ? "Expand" : "Collapse"}
            className="sliderBtn"
            style={{
              marginLeft: ".9rem",
              background: "transparent",
              backgroundColor: "transparent",
              border: "0px ",
            }}
            onClick={() => setIsSlide(!isSlide)}
          >
            {isSlide ? (
              <BiArrowToRight size={30} />
            ) : (
              <BiArrowToLeft size={30} />
            )}
          </button>
          <button
            className="sliderBtn"
            style={{
              marginLeft: "1rem",
              background: "transparent",
              backgroundColor: "transparent",
              border: "0px ",
            }}
            title={isFullScreen ? "Close" : "Full screen"}
            onClick={() => {
              !isEvaluation && setIsSlide(isFullScreen ? true : false);
              setIsFullScreen(!isFullScreen);
            }}
          >
            {isFullScreen ? (
              <ImCancelCircle size={20} />
            ) : (
              <FaExpandArrowsAlt size={20} />
            )}
          </button>
        </div>
      </>
    );
  }
  function Debugsolvermodal() {
    return (
      <>
        <div id="simplemodal" className="pyui_modal">
          <div className="pyui_modal-content">
            <header className="pyui_modal-header">
              <h4 className="pyui_modal-header-title">
                <i class="fa-solid fa-circle-info"></i> Your Score
              </h4>
              <span
                className="closebtn"
                onClick={() => {
                  setIsMOdal(false);
                }}
              ></span>
            </header>

            {submitting && !submiited && (
              <div id="modalloader-spinner">
                <p>
                  Marking as complete
                  <PulseLoader
                    color="black"
                    loading={submitting}
                    cssOverride={override}
                    size={3}
                    className="pulse-loader"
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    speedMultiplier={0.5}
                  />
                </p>
              </div>
            )}
            {submiited && (
              <div className="pyui_modal-body">
                <div className="pyui_modal-body-container">
                  <div className="marked-completed-msg">
                    <i class="fa-solid fa-check"></i>
                    <div>{coinMsg}</div>
                  </div>
                  <div className="modal-footer-msg">
                    <button
                      className="button primary small"
                      onClick={() => {
                        setIsMOdal(false);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  function ImagEditor() {
    const [isPDFLoading, setIsPDFLoading] = useState(imageType === 'pdf');
    return (
      <>
        <div className="code-editor">
          <div
            id="zoom"
            ref={imageDivRef}
            className={"image-div"}
            style={{ scrollSnapType: "x mandatory" }}
          >
            {isPDFLoading && (
              <div
                style={{
                  position: "absolute",
                  alignSelf: "center",
                  zIndex: "2",
                  justifyItems: "center",
                  color: "whitesmoke",
                }}
              >
                Loading
                <span
                  style={{
                    position: 'relative',
                    top: '.25rem'
                  }}
                >
                  <PulseLoader
                    // color="white"
                    color="#7b1fa2"
                    loading={isPDFLoading}
                    cssOverride={override}
                    size={10}
                    className="pulse-loader"
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    speedMultiplier={0.5}
                  />
                </span>
              </div>
            )}
            {imageType === "pdf" ? (
              <iframe
                onLoad={() => {
                  setIsPDFLoading(false);
                }}
                src={`${code}#toolbar=0&navpanes=0&scrollbar=0`}
                width={`${imgDimensions.width * zoom}%`}
                height={`${imgDimensions.height * zoom}%`}
              />
            ) : (
              <img
                ref={imgRef}
                onLoad={() => {
                  imageDivRef.current.scrollLeft =
                    (imageDivRef.current.scrollWidth -
                      imageDivRef.current.scrollWidth / 3 -
                      100) /
                    2;
                }}
                src={code}
                style={{
                  width: `${((imgDimensions.width * 10) / 21) *
                    window.devicePixelRatio *
                    zoom
                    }px`,
                  height: `${((imgDimensions.height * 10) / 28) *
                    window.devicePixelRatio *
                    zoom
                    }px`,
                  margin: "auto",
                }}
                alt="code image"
              />
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="editor-debug">
   
        <div className="editor-lable">
          {/* {title ? title : "Code Viewer"} */}
          {(showImage && mainTab === 'editor')&& <Imgsidepanelbutton/>}

{(isShowImage(step) && mainTab === 'image') && <Pdfsidepanelbutton />}
        <ul className="nav-debug-list">
        <li
          className={` ${mainTab === "editor" ? "tab active" : "tab"
        }`}
          onClick={() => {
            setMainTab("editor");
            setIsSlide(true)

          }}
          >
          {title ? title : "Code Viewer"}
        </li>
       { console.log('is sql', step)}
      {(isShoWTables(step?.qlocation,step?.language)) &&  <li
          className={`${mainTab === "tables" ? "tab active" : "tab"
        }`}
        onClick={() => {
          setMainTab("tables");
        }}
        >
          View tables
        </li>}
        {(isShowPlayground(step?.qlocation,step))&&  <li
          className={`${mainTab === "Playground" ? "tab active" : "tab"
        }`}
        onClick={() => {
          setMainTab("Playground");
        }}
        >
          Playground
         </li>}        
          {/* {(isShowVideo(step))&&  <li
          className={`${mainTab === "Video" ? "tab active" : "tab"
        }`}
        onClick={() => {
          setMainTab("Video");
        }}
        >
          Video Player
         </li>} */}
          {(isShowImage(step))&&  <li
          className={`${mainTab === "image" ? "tab active" : "tab"
        }`}
        onClick={() => {
          setMainTab("image");
        }}
        >
          Additional Information
         </li>}
         {/* <li>
         {(showImage && mainTab === 'editor')&& <Imgsidepanelbutton/>}

         </li> */}
        
      </ul>
      </div>
      {/* )} */}

      <div className="code-editor">
        {mainTab === 'editor' && (
          <>
            {isDebug && isModal && <Debugsolvermodal/>}

            {isNormal && (
              <CommonEditiordebug
                code={code}
                optionsD={optionsD}
                onChange={onChange}
                handleEditorDidMount={handleEditorDidMount}
              />
            )}
            {isTextEditor && (
              <TextEditor
                text={code}
                optionsD={optionsD}
                onChange={onChange}
                handleEditorDidMount={handleEditorDidMount}
              />
            )}
            {(showImage && isEvaluation)&&ImagEditor() }
            {(showImage && !isEvaluation)&& <ImagEditor/>}

           
          </>
        )}
      </div>
      {mainTab === 'tables' && (
        <section style={{
          height: "450px",
          width: "100%", backgroundColor: 'rgba(0,0,0,0.1)'
        }}>
          <SQLTables tablename={'employees'} exid={step.exid} data={step} />
        </section>
      )}
      {mainTab === 'Playground' && (
        <section style={{
          height: "450px",
          width: "100%", backgroundColor: 'rgba(0,0,0,0.1)'
        }}>
          <SQLPlayground step={step}/>
          {/* <SQLTables tablename={'employees'} exid={step.exid} data={step} /> */}
        </section>
      )}
       {mainTab === 'Video' &&
            <StreamVideo
             qlocation={step?.qlocation}
             exid={step?.exid}
            />

            }
            {mainTab === 'image'&& <ImagePDFViewer isFullScreen={isFullScreen} imageType={imageType} setImageType={setImageType} setIsFullScreen={setIsFullScreen} setIsSlide={setIsSlide} Imgsidepanelbutton={Imgsidepanelbutton} step={step} imageDivRef={imageDivRef} imgRef={imgRef} zoom ={zoom}/>}
    </div>
  );
  // return (
  //   <div className="editor-debug">
  //     <div className="editor-lable">
  //       {title ? title : "Code Viewer"}
  //       {showImage && Imgsidepanelbutton()}
  //     </div>
  //     <div className="code-editor">
  //       {isDebug && isModal && Debugsolvermodal()}

  //       {isNormal && (
  //         <CommonEditiordebug
  //           code={code}
  //           optionsD={optionsD}
  //           onChange={onChange}
  //           handleEditorDidMount={handleEditorDidMount}
  //         />
  //       )}
  //       {isTextEditor && (
  //         <TextEditor
  //           text={code}
  //           optionsD={optionsD}
  //           onChange={onChange}
  //           handleEditorDidMount={handleEditorDidMount}
  //         />
  //       )}
  //       {showImage && ImagEditor()}
  //     </div>
  //   </div>
  // );
}

export default LeftHandside;
