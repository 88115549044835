import React, { useState } from "react";
import "./form.css";
import LeftHandside from "./Lefthandside";
import gettitleLHS from "./GetTitleforeditor";
const RenderreaderEditor = ({
  code,
  isSlide,
  handleEditorDidMount,
  ShowConfetti,
  coinMsg,
  status,
  initialData,
  originalData,
  exdatarr,
  currentStepIndex,
  QuesForm,
  isEvaluation,
  DynamicForm,
  step,
  randomexe,
  setCurrentStepIndex,
  isLastStep,
  isFirstStep,
  next,
  back,
  clearTimer,
  Confetti,
  Congrats,
  ConfettiExplosion,
  isTextEditor,
  language,
  module,
  showResult, setPrevIndex, prevIndex, npvalue, statenp, setValue,
  setKey, handlesetAttNtAttp, imageType,setIsSlide ,isFullScreen,setIsFullScreen,fullSizeImage,setImageType

}) => {


  // console.log("npvalue", npvalue);
  const options1 = {
    readOnly: true,
    domReadOnly: true,
    minimap: { enabled: false },
    wordWrap: "on",
    fontSize: "14rem",
    mouseWheelZoom: true,
    fontFamily: "open sans",
    contextmenu: false,
  };

  return (
    <>
      {/* <div>
        <div className="editor-lable">Code viewer</div>
        <div className="code-editor">
          <CommonEditiordebug
            code={code}
            optionsD={options1}
            handleEditorDidMount={handleEditorDidMount}
          />
        </div>
   
      </div> */}
      <LeftHandside
        code={code}
        optionsD={options1}
        handleEditorDidMount={handleEditorDidMount}
        // isNormal={true}
        isNormal={!isTextEditor}
        isTextEditor={isTextEditor}
        title={gettitleLHS(language, module)}
        step={step}
        isSlide={isSlide}
        setIsSlide ={setIsSlide }
        isFullScreen={isFullScreen}
        setIsFullScreen={setIsFullScreen}
        setImageType={setImageType}
        imageType={imageType}
      />
      {/* <div
        className="pyui_code-viewer-questions questions"
   
      >
      
          <h4 className="pyui_code-viewer-questions-title">
            {Object.keys(originalData).length === 1
              ? "Answer the following"
              : "Fill the missing values"}
          </h4>

          <div className="pyui_code-viewer-questions-form question-form">
            <QuesForm
              status={status}
              data={exdatarr[currentStepIndex]}
              index={currentStepIndex}
              originalData={originalData}
              initialData={initialData}
              ShowConfetti={ShowConfetti}
              coinMsg={coinMsg}
            />
          </div>
        
      </div> */}

      <div
        className="pyui_code-viewer-questions questions"
        style={{ display: isSlide ? "" : "none" }}
      >
        {!isEvaluation && (
          <h4 className="pyui_code-viewer-questions-title">
            {Object.keys(originalData).length === 1
              ? "Answer the following"
              : "Fill the missing values"}
          </h4>
        )}

        <div className="pyui_code-viewer-questions-form question-form">
          {!isEvaluation ? (
            <QuesForm
              status={status}
              data={exdatarr[currentStepIndex]}
              index={currentStepIndex}
              originalData={originalData}
              initialData={initialData}
              ShowConfetti={ShowConfetti}
              coinMsg={coinMsg}
              showResult={showResult}
              next={next}
              back={back}
              isLastStep={isLastStep}
              isFirstStep={isFirstStep}
              prevIndex={prevIndex}
              setPrevIndex={setPrevIndex}
              npvalue={npvalue}
              statenp={statenp}
              setValue={setValue}
              setKey={setKey}
              handlesetAttNtAttp={handlesetAttNtAttp}
              module={module}
            />
          ) : (
            <DynamicForm
              exid={step.exid}
              module={module}
              randomexe={randomexe}
              data={randomexe[currentStepIndex]}
              setCurrentStepIndex={setCurrentStepIndex}
              isLastStep={isLastStep}
              isFirstStep={isFirstStep}
              next={next}
              back={back}
              clearTimer={clearTimer}
              showResult={showResult}
              prevIndex={prevIndex}
              setPrevIndex={setPrevIndex}
              npvalue={npvalue}
              statenp={statenp}
              setValue={setValue}
              handlesetAttNtAttp={handlesetAttNtAttp}
              setKey={setKey}
            />
          )}
        </div>
      </div>
      {!isEvaluation && ShowConfetti && (
        <>
          {coinMsg.includes("coins") && coinMsg.includes("badge points") && (
            <div>
              <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                numberOfPieces={500}
              />
            </div>
          )}
          <div className="celebDiv">
            <div className="message-container">
              {coinMsg.includes("coins") &&
                coinMsg.includes("badge points") && (
                  <ConfettiExplosion zIndex={12} />
                )}
              <Congrats msg={coinMsg} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RenderreaderEditor;
