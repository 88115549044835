import { fetchData } from 'Components/ApiUtils/fetchData'
import Loader from 'Components/UtilityFunctions/Loader'
import Table from 'Components/UtilityFunctions/Table'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { RiCloseCircleLine } from 'react-icons/ri'
import { TiTick } from 'react-icons/ti'

export default function AttendedUsers({scheduleid}) {
    const [isLoading, setIsLaoding] = useState(false)
    const [attendeduserData, setAttendedUsers] = useState([])
    const headers =[
            {id:1,field:'userid',headerName:'Userid'},
            // {id:2, field:"scheduleid", headerName:'Scheduleid'},

            {id:2, field:"isattened",headerName:'Attempted'},
            {id:3, field:"submitted",headerName:'Submitted'},
            {id:4, field:"isreset",headerName:'Reset'}
    ]
    async function getAttendedUsersList(){
        setIsLaoding(true)
        
            // let uri =`http://localhost:8090/api/getuserattendance?scheduleid=${scheduleid}&clientpublic=baef7468287a44d8ac3634026d9fb8d1&clientsecret=181a2f3171117c1bd164c88b1171c1b83114fc1712121b12`
            // let result = await axios.get(uri)
            let uri =`getuserattendance?scheduleid=${scheduleid}`
            let result = await fetchData(uri)
            result.data.map(item=> {
              item.submitted =item.isattened ?<TiTick color="#4de04d" size={22} />:<RiCloseCircleLine color="red" size={22} />
              item.isreset =item.isreset ?<TiTick color="#4de04d" size={22} />:<RiCloseCircleLine color="red" size={22} />
              item.isattened= ( item.teststatus=== 'inprogress' || item.teststatus=== 'completed')?<TiTick color="#4de04d" size={22} />:<RiCloseCircleLine color="red" size={22} />
            
            } )
            console.log(result.data)
            setIsLaoding(false)
            setAttendedUsers(result.data)
    }
    useEffect(()=>{
        getAttendedUsersList()
    },[])
  return (
    <>
    {isLoading?
    <>
        <Loader isLoading={isLoading}/>
    </>
    :

        <Table
              headers={headers}
              rows={attendeduserData}
              maxHeight={26}
              isCheckBox={false}
              
            />
        }
    </>
  )
}
