import PulseLoader from "react-spinners/PulseLoader";
import { useRef, useState } from "react";
import { Base64 } from "js-base64";
import { useEffect } from "react";
import FullSizeImage from "./Fullsizeimage";
import { fetchData } from "Components/ApiUtils/fetchData";


export default function ImagePDFViewer({ imgRef,setIsSlide,isFullScreen,setIsFullScreen, step, setImageType,imageType}) {
  
  const [isPDFLoading, setIsPDFLoading] = useState(false);
  // const [imageType, setimageType] = useState('')
  const [imgDimensions, setimgDimensions] = useState([])
  const [dataUrl, setDataUrl]  = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [zoom, setZoom] = useState(1)
  const imageDivRef = useRef(null)
  
  function FullSizeImage2() {
    if(!isFullScreen){
      return 
    }
    return (
      <FullSizeImage
        zoom={zoom}
        imageUrl={dataUrl}
        setZoom={setZoom}
        isFullScreen={isFullScreen}
        setIsFullScreen={setIsFullScreen}
        setIsSlide={setIsSlide}
        imgDimensions={imgDimensions}
        imageType={imageType}
      />
    );
  }
  const override = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "20px",
      // borderColor: "olive",
      // backgroundColor: "red",
      width: "100%",
    };
    async function getImgPDF(){
      let filename=''
      const fileExtenstions =['.png','.jepg', 'pdf']

      if(step?.qlocation.includes(',')){
        filename = step.qlocation.split(',').filter(item=> {
          for (let i = 0; i < fileExtenstions.length; i++) {
            const element = fileExtenstions[i];
            if(item.includes(element)) return item
          }      
        })[0]
        console.log('image', filename)
      }
      setIsLoading(true)
      let result = await fetchData(`getimgpdf?filename=${filename}`)
      if(result.type === 'pdf'){
        const blob =  new Blob( [ new Uint8Array(result.data.data) ], { type: 'application/pdf' } );
        console.log(blob)
        console.log(URL.createObjectURL( blob ))
        setDataUrl(URL.createObjectURL( blob ));
        
      }else{
       console.log(result.data)
        const base64 = Base64.btoa(
          new Uint8Array(result.data.data).reduce(function (data, byte) {
            return data + String.fromCharCode(byte);
          }, "")
        );
          console.log(`data:${result.fileType};base64,${base64}`)
        setDataUrl(`data:${result.fileType};base64,${base64}`);
      
      }
      setImageType(result.type)
      setimgDimensions(result.dimensions)
      setIsLoading(false)

    }
    useEffect(()=>{
        getImgPDF()
    },[])
    return (
      <>
        <div className="code-editor">
          {isLoading?
          
  <div
    style={{
      position: "absolute",
      alignSelf: "center",
      zIndex: "2",
      justifyItems: "center",
      color: "whitesmoke",
      display:'contents'
    }}
  >            {console.log('imageType',imageType === 'pdf')}

    Loading
    <span
      style={{
        position: "relative",
        top: ".25rem",
      }}
    >
      <PulseLoader
        // color="white"
        color="#7b1fa2"
        loading={isLoading}
        cssOverride={override}
        size={10}
        className="pulse-loader"
        aria-label="Loading Spinner"
        data-testid="loader"
        speedMultiplier={0.5}
      />
    </span>
  </div>
          :

          <div
            id="zoom"
            ref={imageDivRef}
            className={"image-div"}
            style={{ scrollSnapType: "x mandatory" }}
          >
            {isPDFLoading && (
              <div
                style={{
                  position: "absolute",
                  alignSelf: "center",
                  zIndex: "2",
                  justifyItems: "center",
                  color: "whitesmoke",
                }}
              >
                Loading
                <span
                  style={{
                    position: "relative",
                    top: ".25rem",
                  }}
                >
                  <PulseLoader
                    // color="white"
                    color="#7b1fa2"
                    loading={isPDFLoading}
                    cssOverride={override}
                    size={10}
                    className="pulse-loader"
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    speedMultiplier={0.5}
                  />
                </span>
              </div>
            )}
            {console.log('imageType',imageType, imageType === "pdf" )}
            {imageType === "pdf" ? (
              <iframe
                onLoad={() => {
                  setIsPDFLoading(false);
                }}
                src={`${dataUrl}#toolbar=0&navpanes=0&scrollbar=0`}
                width={`${imgDimensions.width }%`}
                height={`${imgDimensions.height}%`}
              />
            ) : (
              <img
                ref={imgRef}
                onLoad={() => {
                  imageDivRef.current.scrollLeft =
                    (imageDivRef.current.scrollWidth -
                      imageDivRef.current.scrollWidth / 3 -
                      100) /
                    2;
                }}
                src={dataUrl}
                style={{
                  width: `${((imgDimensions.width * 10) / 21) *
                    window.devicePixelRatio *
                    zoom
                    }px`,
                  height: `${((imgDimensions.height * 10) / 28) *
                    window.devicePixelRatio *
                    zoom
                    }px`,
                  margin: "auto",
                }}
                alt="code image"
              />
            )}
          </div>
          }
                      {/* <FullSizeImage2 /> */}
                     {isFullScreen&&

                       <FullSizeImage
                       zoom={zoom}
                       imageUrl={dataUrl}
                       setZoom={setZoom}
                       isFullScreen={isFullScreen}
                       setIsFullScreen={setIsFullScreen}
                       setIsSlide={setIsSlide}
                       imgDimensions={imgDimensions}
                       imageType={imageType}
                       />

                      }
        </div>
      </>
    );
  }