import ApiConfig from 'ApiConfig'
import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'


export default function StreamVideo({qlocation, exid}) {

  const [link,setLink] = useState('')
  const [isLoading, setIsLoading] = useState(true)
    const videoExtentionArr = ['.mp4','.3gp', '.avi', '.mpg', '.mov', '.wmv']
    let fileLocation = qlocation.split(',').filter(item => item.includes('.mp4'))[0]
    let Api = new ApiConfig()
    // let link = Api.addApiKey(`${Api.BaseURI}/videocloud?fileLocation=${fileLocation}&exid=${exid}`)
    async function getVideoUrl(){
      setIsLoading(true)
      let uri =Api.addApiKey(`${Api.BaseURI}/videosignedurl?fileLocation=${fileLocation}&exid=${exid}`)
      let result = await axios.get(uri)
      console.log(result.data)
      setLink(result.data.url)
      setIsLoading(false)
    }
    useEffect(()=>{
      getVideoUrl()
    },[])
    console.log(link)
  return (
    <div>
      {!isLoading&&

        <video controls width="100%" height={'430'} style={{ border: '1px solid black' }}>
            <source src={link} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
        }
        {/* <ReactPlayer width="100%" height={'430'} controls={true} pip={true} url= {link} /> */}
  
  </div>
  )
}
