import React from 'react'
import './Pop.scss'
export default function PopUp({titleOrIcon, description}) {
    function myFunction() {
        var popup = document.getElementById("myPopup");
        popup.classList.toggle("show");
      }
      
  return (
    <>
    <div>

   
<span className="popup-msg" onClick={myFunction}>{titleOrIcon}
  <span className="popuptext" id="myPopup">{description}</span>
</span>
    </div>

    </>
  )
}
