import { useEffect, useState } from "react"
import { fetchData } from "../../../ApiUtils/fetchData"

const useGetSQLTables = (exid,data) => {
  const [loading, setLoading] = useState(false)
  const [headers, setHeader] = useState([])
  const [rows, setRows] = useState([])
  const [options, setOptions] = useState([])
  const [selectedOptions, setSelectedOptions] = useState('')

  useEffect(() => {
    getOptions()
  }, [selectedOptions])

  async function getOptions() {
    setLoading(true)

    try {
      let options 
      if(data.qlocation !== null ){
      if(data.qlocation?.includes(',') ){

     
       options =data?.qlocation?.split(',')
      if(options[0].includes('.txt')){

        options.shift()
      }
      // options = options.map(item=> {
      //   console.log("item.includes('.csv')",item.includes('.csv'),item)
      //   if(item.includes('.csv')){

      //    return item.slice(0, item.indexOf('.csv'))
      //   }
      //   return item
      // })
    }
    else if(!data?.qlocation.includes('txt') && data?.qlocation.length > 0){
      options=[data?.qlocation]

    }
    }
      setOptions(options)
      // setSelectedOptions(options[0])
      console.log(options)
      let uri2 = ''
      console.log('selectedOptions', selectedOptions);
      if (selectedOptions !== '') {
        console.log('if');
        uri2 = `getsqltables?tablename=${selectedOptions}`
      } else {
        console.log('else');
        uri2 = `getsqltables?tablename=${options[0]}`
        // setSelectedOptions(options[0])
      }
      console.log('uri', uri2);
      const tables = await fetchData(uri2)
      console.log('tables', tables);
      setHeader(tables?.headers)
      setRows(tables?.rows)
      console.log('options', options);
      // setOptions(options)
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  }


  return { headers, rows, loading, options, setOptions, selectedOptions, setSelectedOptions, getOptions }
}

export default useGetSQLTables