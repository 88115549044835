import React, { useMemo, useRef, useState, useEffect } from "react";
import Editor from "@monaco-editor/react";
import { postData } from "../../ApiUtils/postData";
import { fetchData } from "../../ApiUtils/fetchData";
import Table from "Components/UtilityFunctions/Table";
import ClockLoader from "react-spinners/ClockLoader";
import './sqltable.scss'
import { IoMdInformationCircle } from "react-icons/io";
import { getValueFromSession, setValueInSession } from "Components/UtilityFunctions/getSetValueSession";
import Loader from "Components/UtilityFunctions/Loader";
import { FaPlay } from "react-icons/fa";
import PopUp from "Components/UtilityFunctions/UI/PopUp/PopUp";
import { Tooltip } from "react-tooltip";




export default function SQLPlayground({step}) {

    const [code, setCode] = useState('-- write your query here.')
    const [output, setOutput] = useState([])
    const [error, setError] = useState('')
    const [headers, setHeaders] =useState([])
    const [table, setTables] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isLoading2, setIsLoading2] = useState(false)
    const [SqlRuncount, setSqlRunCunt] = useState(0)
    const [isShowRunQuery, setIsShowRunQuery] = useState(false)
    const onChange = (code)=>{
        setCode(code)
    }

    useEffect(()=>{
      geTables()
    },[])

    // function to set sql runcount and to disabled run query bnt
   


    //  function to run query 
    const runQuery = async()=>{
      if(code === ''|| code.length <1 || code === '-- write your query here.'){
        setError('Please write query.')
        return
      }
      else if(isLoading){
        return 
      }
      setIsLoading(true)
        setError('')
        setOutput([])
        let uri ='runsqlplaygroundquery'
        let userid =getValueFromSession('username')
        console.log('userid ',userid)
        let data={
            query:code,
            userid
        }
        let result = await postData(uri, data)
        // console.log(result.data)
        if(result.data.status ===200){
          
            setOutput(result.data.output)

            // console.log(typeof result.data.output,result.data.output, result.data)
            if(typeof result.data.output === 'object')
            {

              setHeaders(Object.keys(result.data.output[0]))
            }
        }
        else{
            setError(result.data.error)
        }
        setIsLoading(false)
    }
    const getHeaders = ()=>{
        let data= headers.map(item=>{
          console.log(item)
          return {field:item,headerName:item}
        })
        console.log("data",data)
        return data
      }
      const geTables= ()=>{
          let options 
          if(step.qlocation !== null && step.qlocation?.includes(',') ){
          
          options =step?.qlocation?.split(',')
          if(options[0].includes('.txt')){

            options.shift()
          }
          options = options.map(item=> {
            if(item.includes('.csv')){

            return item.slice(0, item.indexOf('.csv'))
            }
            return item
          })
        }
        setTables(options)
      }
      const override = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "10%",
        zIndex: "10",
      };

  return (
    isLoading2? <Loader 
      isLoading={isLoading2}
      msg="loading"
      size={10}
    />
    :
    <>
    {/* <div>SQLPlayground</div> */}
    <div>

    <Tooltip
          style={{ zIndex: 1 }}
          anchorSelect="#runquery"
          content={isShowRunQuery?"Reached run query limit":"Run query"}
          place="top"
        />
   <div className="tables-icon-div">

    
     <p><b>Tables:</b> {table?.length>0?table:'use relevant tables.'}</p>
     <div style={{display:'flex',gap:'1rem',margin:"0 1rem",alignItems:'center'}}>
    
     <PopUp 
     titleOrIcon={<IoMdInformationCircle size={30} color="#2876f0"/>}
     description={'You can use this playground to execute any queries relevant to answer the exercises. Please make use of this tool judiciously.'}
     />
      <div>

   
      <span id="runquery">
      <button 
      className={`icon-button2 small ${isShowRunQuery?'disabled':''}`}
      // className={`button secondary small ${process.env.REACT_APP_SQL_RUN_COUNT<= SqlRuncount?'':'disabled'}`}
      onClick={runQuery}
      
      >

     
       {isLoading?
       <>
        <ClockLoader
                  color="#7b1fa2"
                  loading={isLoading}
                  // cssOverride={override}
                  size={25}
                  // className="pulse-loader"
                  // aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={0.5}
                  />
       </>
        :
        <FaPlay />
      } 
      </button>
      </span>
      </div>
      {/* {process.env.REACT_APP_USE_SQL_RUN_COUNT === 'true' &&<span className="sql-run-test-count ">Run count :<span style={{marginLeft:'.5rem'}}>{SqlRuncount}</span></span>}  */}
      </div>
      </div>
    </div>
    <div style={{display:'contents'}}>
      {/* <div> */}

    <Editor
        height="31%"
        width="100%"
        language={'sql'}
        theme="vs-dark"
        value={code}
        defaultValue={code}
        onChange={onChange}
      />
      
    
      {/* </div> */}
      {(error!=='' || error.length > 0)?
            <p>{error}</p>
          :typeof output === 'object'?
          <Table
          rows={output}
          headers={ getHeaders()}
          isCheckBox={false}
          maxHeight={16}
          />:
          <p style={{padding:'1rem'}}>{output}</p>
        }
        </div>
    </>
  
  )
}


const TableForSQL=({headers, rows,headerKeys})=>{
    return<>
    <table>
              <thead>
                <tr>
                  <th>#</th>
                  {headers?.map(header => (
                    <th key={Object.keys(header)[0]}>{Object.values(header)[0]}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows?.map((row, index) => (
                  <tr key={index}>
                    <td>{index+1}</td>
                    {headerKeys?.map(key => (
                      <td key={key}>{row[key]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
    </>
}
