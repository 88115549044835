// import libraries
import { MdOutlineLastPage, MdFirstPage, MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
// import styles
import './paginationeditor.scss'
import { getValueFromSession } from "../../../UtilityFunctions/getSetValueSession";

const PaginationEditor = ({ dataArray, currentIndex, setCurrentStepIndex, setPrevIndex, setIsLoading, evaluate, step, model, statenp, calcAttpNttp, setFromPagi, module, ifAttempted, handleSetResults, testLoader }) => {
  // const totalPages = Math.ceil(dataArray?.length / dataPerPage

  // console.log("handleChangeColor", handleChangeColor(0))
  const handleChangeColor = (pg) => {
    let results = getValueFromSession("results") ? JSON.parse(getValueFromSession("results")) : [];
    let attNtAttp = getValueFromSession("attNtAttp") ? JSON.parse(getValueFromSession("attNtAttp")) : [];
    // console.log("attNtAttp", attNtAttp)
    let color = false
    if (results[pg]?.exercise === attNtAttp[pg]?.exid) {
      let check = results[pg]?.questions === attNtAttp[pg]?.count?.attempted
      // console.log("check", check)
      // console.log("results[pg]?.questions", results[pg]?.questions)
      // console.log("attNtAttp[pg]?.count?.attempted", attNtAttp[pg]?.count?.attempted)
      if (check) {
        color = true
      }
    }
    return color
  }


  const size = 20

  const nextPage = (pg) => {
    if(testLoader){
      return
    }
    let results = evaluate(model, step.exid, step.level)
    setIsLoading(true);
    setPrevIndex(currentIndex);
    setCurrentStepIndex(prevIndex => Math.min(prevIndex + 1, dataArray.length - 1));
    if (results) {
      calcAttpNttp(results)
    }
    if (module === 'debug' || module === 'solver') {
      setFromPagi(true)
      if (ifAttempted) {
        handleSetResults()
      }
    }
    handleChangeColor(Math.min(currentIndex + 1, dataArray.length - 1))
    statenp({})
  };

  // Function to handle previous page
  const prevPage = () => {
    if(testLoader){
      return
    }
    let results = evaluate(model, step.exid, step.level)
    setIsLoading(true);
    setPrevIndex(currentIndex);
    setCurrentStepIndex(prevIndex => Math.max(prevIndex - 1, 0));
    if (results) {
      calcAttpNttp(results)
    }
    if (module === 'debug' || module === 'solver') {
      setFromPagi(true)
      if (ifAttempted) {
        handleSetResults()
      }
    }
    handleChangeColor(Math.max(currentIndex - 1, 0))
    statenp({})
  };

  // Function to handle first page
  const firstPage = () => {
    if(testLoader){
      return
    }
    let results = evaluate(model, step.exid, step.level)
    setIsLoading(true);
    setPrevIndex(currentIndex);
    setCurrentStepIndex(0);
    if (results) {
      calcAttpNttp(results)
    }
    if (module === 'debug' || module === 'solver') {
      setFromPagi(true)
      if (ifAttempted) {
        handleSetResults()
      }
    }
    handleChangeColor(0)
    statenp({})
  };

  // Function to handle last page
  const lastPage = (pg) => {
    if(testLoader){
      return
    }
    let results = evaluate(model, step.exid, step.level)
    setIsLoading(true);
    setPrevIndex(currentIndex);
    setCurrentStepIndex(dataArray.length - 1);
    if (results) {
      calcAttpNttp(results)
    }
    if (module === 'debug' || module === 'solver') {
      setFromPagi(true)
      if (ifAttempted) {
        handleSetResults()
      }
    }
    handleChangeColor(dataArray.length - 1)
    statenp({})
  };

  // Function to handle specific page
  const goToPage = (pageNumber) => {
    if(testLoader){
      return 
    }
    if (pageNumber !== currentIndex) {
      setIsLoading(true);
      let results = evaluate(model, step.exid, step.level)
      console.log("goToPage", results)
      setCurrentStepIndex(Math.min(Math.max(pageNumber, 0), dataArray.length - 1));
      if (results) {
        calcAttpNttp(results)
      }
      if (module === 'debug' || module === 'solver') {
        setFromPagi(true)
        if (ifAttempted) {
          handleSetResults()
        }
      }
      statenp({})
    }
    setPrevIndex(currentIndex);
  };


  // Generate pagination buttons
  const pageNumbers = [];
  for (let i = 0; i <= dataArray?.length - 1; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="pagination-editor">


      {/* First page button */}
      <button onClick={firstPage} disabled={currentIndex === 0}
        className={`pagination-button-pre_next ${testLoader?'disabled':''}`} id="first">
        <MdFirstPage size={size} />
      </button>

      {/* Previous button */}
      <button onClick={prevPage} disabled={currentIndex === 0}
        className={`pagination-button-pre_next ${testLoader?'disabled':''}`} id="prev">
        <GrFormPrevious size={size} />
      </button>

      {/* Display ellipses if not on the first page */}
      {/* {currentPage > 3 && <span className="pagination-ellipsis">...</span>} */}

      {/* Page buttons */}
      {pageNumbers?.map((pageNumber) => (
        <button
          style={{
            borderColor: `${handleChangeColor(pageNumber) ? 'green'
              : '#BEBEBE'}`,
          }}
          key={pageNumber}
          onClick={() => goToPage(pageNumber)}
          className={`pagination-button ${testLoader?'disabled':''} ${pageNumber === currentIndex ? 'active' : ''}`}
        >
          {pageNumber + 1}
        </button>
      ))}
      {/* Display ellipses if not on the last page */}
      {/* {currentPage < totalPages - 2 && <span className="pagination-ellipsis">...</span>} */}

      {/* Next button */}
      <button onClick={nextPage} disabled={currentIndex === dataArray?.length - 1} className={`pagination-button-pre_next ${testLoader?'disabled':''}`} id="next">
        <MdNavigateNext size={size} />
      </button>

      {/* Last page button */}
      <button onClick={lastPage} disabled={currentIndex === dataArray?.length - 1} className={`pagination-button-pre_next ${testLoader?'disabled':''}`} id="last">
        <MdOutlineLastPage size={size} />
      </button>



      {/* Display current data */}
      {/* <ul className="data-list">
        {currentData?.map((item) => (
          <li key={item.exid} className="data-item">
            {item.title}
          </li>
        ))}
      </ul> */}
    </div>
  );
}

export default PaginationEditor